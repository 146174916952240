import { styled } from 'tamagui'

import Page from '../Page'

const PageCentered = styled(Page, {
  justifyContent: 'center',
  alignItems: 'center',
  $mobile: {
    flexGrow: 1,
    height: 'calc(100svh - 124px)',
  },
})

export default PageCentered
