'use client'

import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import Heading from '@lyra/core/components/Heading'
import Icon, { AlertTriangle } from '@lyra/core/components/Icon'
import PageCentered from '@lyra/core/components/PageCentered'
import React from 'react'
import { useIntercom } from 'react-use-intercom'
import { YStack } from 'tamagui'

import { isDev } from '../constants/env'

export default function Error({ error }: { error: Error & { digest?: string } }) {
  console.error(error)
  const { show } = useIntercom()
  return (
    <PageCentered>
      <YStack gap="$4" alignItems="center" justifyContent="center">
        <Icon icon={<AlertTriangle />} color="red" size={64} />
        <Heading size="h3">Something went wrong</Heading>
        {isDev ? (
          <BodyText color="secondary" size="lg" textAlign="center">
            {error.toString()}
          </BodyText>
        ) : (
          <BodyText color="secondary">
            An unexpected error occurred. Please contact support.
          </BodyText>
        )}
        <Button label="Contact Support" color="red" isSolid size="lg" onPress={show} width={180} />
      </YStack>
    </PageCentered>
  )
}
